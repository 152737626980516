import React, { Component } from 'react';
import _ from 'lodash';
import { helpers } from "@cargo/common";

let counter = 0;

let isMac = helpers.isMac();

const shortcuts = {
	'escape': 			{ keyCode: 27, metaKey: false,	shiftKey: false, altKey: false, macCtrlKey: false},
	'delete': 			{ keyCode: 8, metaKey: false,	shiftKey: false, altKey: false, macCtrlKey: false},
	'enter': 			{ keyCode: 13, metaKey: false, 	shiftKey: false, altKey: false, macCtrlKey: false},
	'cmd+shift+1': 		{ keyCode: 49, metaKey: true,	shiftKey: true, altKey: false, macCtrlKey: false},
	'cmd+shift+2': 		{ keyCode: 50, metaKey: true,	shiftKey: true, altKey: false, macCtrlKey: false},
	'shift+cmd+1': 		{ keyCode: 50, metaKey: true,	shiftKey: true, altKey: false, macCtrlKey: false},
	'shift+cmd+2': 		{ keyCode: 51, metaKey: true,	shiftKey: true, altKey: false, macCtrlKey: false},
	'ctrl+shift+i': 	{ keyCode: 73, metaKey: false,	shiftKey: true, altKey: false, macCtrlKey: true},
	'cmd+shift+g': 		{ keyCode: 71, metaKey: true,	shiftKey: true, altKey: false, macCtrlKey: false},
};

// populate each shortcut with an empty callback map
_.each(shortcuts, function(value, key){
	shortcuts[key].callbacks = new Map();
});

const onKeyDown = (context, event) => {
	
	// don't handle keydown multiple times
	if(event.repeat) {
		return;
	}

	let shortcutKey = _.findKey(shortcuts, {
		'keyCode' : event.keyCode, 
		'macCtrlKey': isMac ? event.ctrlKey : false,
		'metaKey' : isMac ? event.metaKey : event.ctrlKey, 
		'shiftKey': event.shiftKey,
		'altKey'  : event.altKey
	});

	let shortcut = shortcuts[shortcutKey];
	
	if (shortcut && shortcut.boundTo !== context) {

		if (shortcut && shortcut.callbacks.size > 0) {
			executeShortcut( event, shortcut );
		}

	}

}

const executeShortcut = ( e, shortcut ) => {

	// Pull last callback from the shortcut callback map
	//const callback  = Array.from(shortcut.callbacks.values()).pop();
	// if( callback ){

		// 	// only prevent default after we made sure there's a callback to execute
		// 	e.preventDefault();
	
		// 	console.log('callback', callback)
	
		// 	// execute
		// 	callback(e);
	
		// } else {
		// 	console.warn("No callback found for " + shortcut)
	// }

	const callbacks = Array.from(shortcut.callbacks.values());

	if ( callbacks ) {
		e.preventDefault();
		callbacks.forEach((cb) => cb(e));
	} else {
		console.warn("No callback found for " + shortcut)
	}

}

const initEventListeners = _.once(() => {

	if(helpers.isServer) {
		return;
	}

	// add event listener to the editor window
	window.addEventListener("keydown", e => {
		onKeyDown('editor', e)
	});

});

class HotKey extends Component {

	constructor(props) {

		super(props);

		if(!props.shortcut) {
			throw 'don\'t define key handlers without a keyCode prop.';
		}

		if(!props.callback) {
			throw 'don\'t define key handlers without a callback prop.';
		}
		// associate the callback passed with the keycode
		if (shortcuts[props.shortcut]) {

			// generate new id for this hotkey
			this.id = counter++;

			// add this hotkey's callback to the shortcut's callback map. Use the id as a key
			// so it can be easily removed later
			shortcuts[props.shortcut].callbacks.set(this.id, props.callback);

		}

		initEventListeners();
	}

	render = () => null

	componentDidMount() {
		// // associate the callback passed with the keycode
		// if (shortcuts[props.shortcut]) {

		// 	// generate new id for this hotkey
		// 	this.id = counter++;

		// 	// add this hotkey's callback to the shortcut's callback map. Use the id as a key
		// 	// so it can be easily removed later
		// 	shortcuts[props.shortcut].callbacks.set(this.id, props.callback);

		// }
	}

	componentWillUnmount() {
		
		const shortcut = shortcuts[this.props.shortcut];

		if (shortcut) {

			// remove the callback associated with this hotkey's id
			shortcut.callbacks.delete(this.id);

		} else {
			
			console.log('Unable to delete shortcut callback for:', this.props.shortcut)

		}

	}

}

export {
	HotKey
};
